/* GOOGLE Fonts */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i");
/* Fonts */
/* Dimensions */
/* Colors */
/* Branding */
/* Paymentcomponents */
/* BoC */
/* Ancoria */
/* Astrobank */
/* Hellenic */
/* Eurobank */
/* Piraeus */
/* Alpha ConnectToBank */
/* NBG */
/* Optima */
/* Natwest */
/* HSBC */
/* RCB */
/* CDB */
/* BBAC */
/* Revolut */
/* Animation */
.inline-arrow {
  border: solid #666666;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  opacity: 0.6; }
  .inline-arrow.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg); }

/* Keyframes */
@keyframes counterClockwiseSpin {
  0% {
    transform: rotate(180deg); }
  100% {
    transform: rotate(0deg); } }

@keyframes clockwiseSpin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(180deg); } }

@keyframes lds-circle {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
  0% {
    transform: rotateY(0deg); }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  100% {
    transform: rotateY(3600deg); } }

/* SPRITES */
.bg-alpha {
  width: 32px;
  height: 32px;
  display: block;
  background: url("../img/banks_sprite.png") -10px -10px;
  overflow: hidden; }

.bg-attica {
  width: 32px;
  height: 29px;
  display: block;
  background: url("../img/banks_sprite.png") -62px -62px;
  overflow: hidden; }

.bg-eurobank {
  width: 32px;
  height: 32px;
  display: block;
  background: url("../img/banks_sprite.png") -61px -10px;
  overflow: hidden; }

.bg-nbg {
  width: 35px;
  height: 25px;
  display: block;
  background: url("../img/banks_sprite.png") -114px -10px;
  overflow: hidden; }

.bg-piraeus {
  width: 32px;
  height: 32px;
  display: block;
  background: url("../img/banks_sprite.png") -10px -62px;
  overflow: hidden; }

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased; }

html {
  height: 100%; }

body {
  position: relative;
  min-height: 100%; }

header {
  width: 100%;
  height: 105px;
  border-bottom: 1px solid #eaeaea; }
  header section {
    max-width: 1200px;
    height: inherit;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }
    header section .logo__link {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      font: normal normal 600 50px/0.82 "Open Sans", sans-serif;
      letter-spacing: -4px;
      color: #6C0022;
      margin-left: 25px; }
      header section .logo__link:hover {
        text-decoration: none; }
    header section .logo__img {
      height: 80px;
      width: auto;
      margin-right: -5px; }

.App {
  height: 100vh; }

main {
  width: 1200px;
  margin: 80px auto 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%; }
  main section {
    width: calc(100% - 300px);
    padding: 0 20px;
    height: 100%; }

.landing__info {
  font: normal normal 600 18px/0.82 "Open Sans", sans-serif;
  padding: 40px 0 0 40px; }

aside {
  width: 300px;
  padding: 0 10px; }
  aside ul {
    list-style: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0; }
    aside ul li .menu__item {
      font: normal normal 600 14px/1 "Open Sans", sans-serif;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding: 5px 15px;
      height: 35px; }
      aside ul li .menu__item--symbol {
        width: 20px;
        height: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center; }
        aside ul li .menu__item--symbol.minus {
          font-size: 30px;
          -webkit-animation: clockwiseSpin 0.2s linear 1;
          -moz-animation: clockwiseSpin 0.2s linear 1;
          -o-animation: clockwiseSpin 0.2s linear 1;
          animation: clockwiseSpin 0.2s linear 1; }
        aside ul li .menu__item--symbol.plus {
          font-size: 20px;
          -webkit-animation: counterClockwiseSpin 0.2s linear 1;
          -moz-animation: counterClockwiseSpin 0.2s linear 1;
          -o-animation: counterClockwiseSpin 0.2s linear 1;
          animation: counterClockwiseSpin 0.2s linear 1; }
      aside ul li .menu__item--inner {
        padding: 5px 20px 5px 30px; }
      aside ul li .menu__item:hover, aside ul li .menu__item.__active {
        background-color: #f2f2f1; }
  aside a {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-decoration: none !important; }
    aside a span {
      font: normal normal 700 13px/1 "Open Sans", sans-serif;
      color: #666666; }
    aside a .inline-arrow {
      transition: transform 0.2s ease;
      margin-right: 5px; }
    aside a:hover .inline-arrow, aside a.__active .inline-arrow {
      transform: translateX(3px) rotate(-45deg);
      opacity: 1; }

.credentials {
  width: 566px;
  table-layout: fixed;
  border-collapse: collapse;
  margin: 35px 0 0 0; }
  .credentials span {
    display: block;
    text-align: center;
    padding: 0;
    font: normal normal 600 15px/1.2 "Open Sans", sans-serif;
    color: #6c757d; }
  .credentials__tbl {
    margin: 25px 0 0 0; }
  .credentials tr th, .credentials tr td {
    text-align: left !important;
    width: 192px; }
  .credentials tr th {
    font: normal normal 600 15px/1.2 "Open Sans", sans-serif; }
  .credentials tr td {
    font: normal normal 400 15px/1.2 "Open Sans", sans-serif; }
  .credentials thead tr {
    display: block; }
  .credentials thead th {
    border-right: 0;
    border-left: 0; }
  .credentials tbody {
    display: block;
    overflow-y: auto;
    max-height: 108px; }

.credentials-info {
  margin-top: 35px; }
  .credentials-info--specific {
    margin-top: 15px; }

.connect-to-provider {
  padding-top: 20px;
  width: 66%; }
  .connect-to-provider__logo {
    display: block;
    margin: 0 auto 25px; }
  .connect-to-provider__props {
    margin: 15px 0 0 0; }
  .connect-to-provider__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; }
  .connect-to-provider__label {
    text-align: center;
    position: relative;
    margin: 30px 0 20px 0;
    width: 100%;
    color: #6c757d;
    font: normal normal 600 14px/1.2 "Open Sans", sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
    .connect-to-provider__label:before, .connect-to-provider__label:after {
      width: calc(50% - 50px);
      content: '';
      display: flex;
      position: absolute;
      bottom: 6px;
      border-bottom: 1px solid #bcc1c6; }
    .connect-to-provider__label:before {
      left: 0; }
    .connect-to-provider__label:after {
      right: 0; }
  .connect-to-provider__connection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; }
  .connect-to-provider__scopes {
    width: 100%; }
    .connect-to-provider__scopes--radio {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      width: 70%;
      margin: 10px auto; }
      .connect-to-provider__scopes--radio label {
        cursor: pointer;
        margin: 0 25px 0 0;
        display: flex;
        flex-direction: row;
        justify-content: stretch;
        align-items: center; }
        .connect-to-provider__scopes--radio label input {
          cursor: pointer; }
        .connect-to-provider__scopes--radio label input:disabled + span,
        .connect-to-provider__scopes--radio label input:disabled {
          cursor: not-allowed;
          opacity: 0.5;
          user-select: none; }
        .connect-to-provider__scopes--radio label span {
          margin-left: 6px;
          color: #6c757d;
          font: normal normal 400 14px/1 "Open Sans", sans-serif; }
  .connect-to-provider__btn {
    border-radius: 30px !important;
    outline: transparent !important;
    padding: 10px 25px;
    font: normal normal 700 18px/1.5 "Open Sans", sans-serif;
    width: auto;
    min-width: 180px;
    margin: 5px 0 0 0; }
  .connect-to-provider__payments {
    overflow: hidden;
    transition: all .5s ease-in-out;
    width: 100%; }
    .connect-to-provider__payments.expanded {
      height: 435px; }
    .connect-to-provider__payments.collapsed {
      height: 0; }
    .connect-to-provider__payments input[type="text"],
    .connect-to-provider__payments input[type="number"],
    .connect-to-provider__payments select,
    .connect-to-provider__payments textarea {
      border: 1px solid #C1C4C7;
      border-radius: 3px;
      outline: none;
      color: #6c757d;
      font: normal normal 400 13px/1.6 "Open Sans", sans-serif; }
    .connect-to-provider__payments input {
      padding: 5px 8px; }
    .connect-to-provider__payments select {
      height: 32px;
      padding: 5px;
      cursor: pointer; }
    .connect-to-provider__payments textarea {
      resize: none;
      padding: 5px 8px; }
      .connect-to-provider__payments textarea::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #afb5ba; }
      .connect-to-provider__payments textarea::-moz-placeholder {
        /* Firefox 19+ */
        color: #afb5ba; }
      .connect-to-provider__payments textarea:-ms-input-placeholder {
        /* IE 10+ */
        color: #afb5ba; }
      .connect-to-provider__payments textarea:-moz-placeholder {
        /* Firefox 18- */
        color: #afb5ba; }
  .connect-to-provider__options {
    margin: 30px 0 25px 0;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end; }
  .connect-to-provider__cases {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: -185px; }
    .connect-to-provider__cases label {
      color: #6c757d;
      margin: -17px 0 3px 1px;
      text-align: center;
      font: normal normal 500 12px/1.2 "Open Sans", sans-serif; }
  .connect-to-provider__autofill {
    padding: .375rem .75rem .375rem 1.15rem;
    position: relative;
    margin: 0 0 0 20px; }
    .connect-to-provider__autofill i {
      margin: -7px 8px 0 0; }
  .connect-to-provider__clear {
    border: 1px solid #212529; }
    .connect-to-provider__clear i {
      margin: 0 8px 0 0; }
  .connect-to-provider__autofill, .connect-to-provider__clear {
    font: normal normal 500 15px/1.2 "Open Sans", sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .connect-to-provider__autofill b, .connect-to-provider__clear b {
      width: 23px;
      height: 10px;
      display: block;
      position: absolute;
      left: 12px;
      bottom: 8px;
      border: 1px dashed #ffffff; }

.payments__form {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 20px 0 0 0; }
  .payments__form--field {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch; }
    .payments__form--field:last-child {
      margin: 0 0 0 0; }
    .payments__form--field:not(:last-child) {
      width: calc(50% - 10px); }
    .payments__form--field:last-child {
      width: 100%; }
    .payments__form--field:nth-child(even):not(:last-child) {
      margin: 0 0 20px 10px; }
    .payments__form--field:nth-child(odd):not(:last-child) {
      margin: 0 10px 20px 0; }
    .payments__form--field label {
      color: #6c757d;
      font: normal normal 500 13px/1.2 "Open Sans", sans-serif; }

.paymentcomponents-cls {
  background-color: #098cb3;
  color: #ffffff;
  border: 1px solid #098cb3;
  -moz-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5); }
  .paymentcomponents-cls:hover {
    background-color: #076e8c !important;
    border: 1px solid #076e8c !important;
    -moz-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5); }
  .paymentcomponents-cls:disabled {
    opacity: 0.6;
    filter: grayscale(60%);
    cursor: not-allowed;
    box-shadow: none !important; }
    .paymentcomponents-cls:disabled:hover {
      background-color: #098cb3 !important; }

.boc-cls {
  background-color: #00697e;
  color: #f8a70e;
  border: 1px solid #00697e;
  -moz-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5); }
  .boc-cls:hover {
    background-color: #004755 !important;
    border: 1px solid #004755 !important;
    -moz-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5); }
  .boc-cls:disabled {
    opacity: 0.6;
    filter: grayscale(60%);
    cursor: not-allowed;
    box-shadow: none !important; }
    .boc-cls:disabled:hover {
      background-color: #00697e !important; }

.ancoria-cls {
  background-color: #F15A22;
  color: #ffffff;
  border: 1px solid #F15A22;
  -moz-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5); }
  .ancoria-cls:hover {
    background-color: #dc460e !important;
    border: 1px solid #dc460e !important;
    -moz-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5); }
  .ancoria-cls:disabled {
    opacity: 0.6;
    filter: grayscale(60%);
    cursor: not-allowed;
    box-shadow: none !important; }
    .ancoria-cls:disabled:hover {
      background-color: #F15A22 !important; }

.astrobank-cls {
  background-color: #fec52e;
  color: #172751;
  border: 1px solid #fec52e;
  -moz-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5); }
  .astrobank-cls:hover {
    background-color: #feba05 !important;
    border: 1px solid #feba05 !important;
    -moz-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5); }
  .astrobank-cls:disabled {
    opacity: 0.6;
    filter: grayscale(60%);
    cursor: not-allowed;
    box-shadow: none !important; }
    .astrobank-cls:disabled:hover {
      background-color: #fec52e !important; }

.hellenic-cls {
  background-color: #f6921e;
  color: #ffffff;
  border: 1px solid #f6921e;
  -moz-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5); }
  .hellenic-cls:hover {
    background-color: #e27e09 !important;
    border: 1px solid #e27e09 !important;
    -moz-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5); }
  .hellenic-cls:disabled {
    opacity: 0.6;
    filter: grayscale(60%);
    cursor: not-allowed;
    box-shadow: none !important; }
    .hellenic-cls:disabled:hover {
      background-color: #f6921e !important; }

.eurobank-gr-cls,
.eurobank-cy-cls {
  background-color: #ea1e2d;
  color: #ffffff;
  border: 1px solid #ea1e2d;
  -moz-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5); }
  .eurobank-gr-cls:hover,
  .eurobank-cy-cls:hover {
    background-color: #cc1321 !important;
    border: 1px solid #cc1321 !important;
    -moz-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5); }
  .eurobank-gr-cls:disabled,
  .eurobank-cy-cls:disabled {
    opacity: 0.6;
    filter: grayscale(60%);
    cursor: not-allowed;
    box-shadow: none !important; }
    .eurobank-gr-cls:disabled:hover,
    .eurobank-cy-cls:disabled:hover {
      background-color: #ea1e2d !important; }

.piraeus-cls {
  background-color: #fbb900;
  color: #0d3880;
  border: 1px solid #fbb900;
  -moz-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5); }
  .piraeus-cls:hover {
    background-color: #d29b00 !important;
    border: 1px solid #d29b00 !important;
    -moz-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5); }
  .piraeus-cls:disabled {
    opacity: 0.6;
    filter: grayscale(60%);
    cursor: not-allowed;
    box-shadow: none !important; }
    .piraeus-cls:disabled:hover {
      background-color: #fbb900 !important; }

.alpha-gr-cls {
  background-color: #11366b;
  color: #ffffff;
  border: 1px solid #11366b;
  -moz-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5); }
  .alpha-gr-cls:hover {
    background-color: #0b2448 !important;
    border: 1px solid #0b2448 !important;
    -moz-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5); }
  .alpha-gr-cls:disabled {
    opacity: 0.6;
    filter: grayscale(60%);
    cursor: not-allowed;
    box-shadow: none !important; }
    .alpha-gr-cls:disabled:hover {
      background-color: #11366b !important; }

.optima-cls {
  background-color: #2F0037;
  color: #ffffff;
  border: 1px solid #2F0037;
  -moz-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5); }
  .optima-cls:hover {
    background-color: #FF7D00 !important;
    border: 1px solid #0c000e !important;
    -moz-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5); }
  .optima-cls:disabled {
    opacity: 0.6;
    filter: grayscale(60%);
    cursor: not-allowed;
    box-shadow: none !important; }
    .optima-cls:disabled:hover {
      background-color: #2F0037 !important; }

.alpha-cy-cls {
  background-color: #11366b;
  color: #ffffff;
  border: 1px solid #11366b;
  -moz-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5); }
  .alpha-cy-cls:hover {
    background-color: #0b2448 !important;
    border: 1px solid #0b2448 !important;
    -moz-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5); }
  .alpha-cy-cls:disabled {
    opacity: 0.6;
    filter: grayscale(60%);
    cursor: not-allowed;
    box-shadow: none !important; }
    .alpha-cy-cls:disabled:hover {
      background-color: #11366b !important; }

.nbg-cls,
.nbg-cy-cls {
  background-color: #006271;
  color: #ffffff;
  border: 1px solid #006271;
  -moz-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5); }
  .nbg-cls:hover,
  .nbg-cy-cls:hover {
    background-color: #003f48 !important;
    border: 1px solid #003f48 !important;
    -moz-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5); }
  .nbg-cls:disabled,
  .nbg-cy-cls:disabled {
    opacity: 0.6;
    filter: grayscale(60%);
    cursor: not-allowed;
    box-shadow: none !important; }
    .nbg-cls:disabled:hover,
    .nbg-cy-cls:disabled:hover {
      background-color: #006271 !important; }

.rcb-cls {
  background-color: #004B8D;
  color: #ffffff;
  border: 1px solid #004B8D;
  -moz-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5); }
  .rcb-cls:hover {
    background-color: #003564 !important;
    border: 1px solid #003564 !important;
    -moz-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5); }
  .rcb-cls:disabled {
    opacity: 0.6;
    filter: grayscale(60%);
    cursor: not-allowed;
    box-shadow: none !important; }
    .rcb-cls:disabled:hover {
      background-color: #004B8D !important; }

.cdb-cls {
  background-color: #73AA2B;
  color: #ffffff;
  border: 1px solid #73AA2B;
  -moz-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5); }
  .cdb-cls:hover {
    background-color: #5d8923 !important;
    border: 1px solid #5d8923 !important;
    -moz-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5); }
  .cdb-cls:disabled {
    opacity: 0.6;
    filter: grayscale(60%);
    cursor: not-allowed;
    box-shadow: none !important; }
    .cdb-cls:disabled:hover {
      background-color: #73AA2B !important; }

.bbac-cls {
  background-color: #0EADB3;
  color: #ffffff;
  border: 1px solid #0EADB3;
  -moz-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5); }
  .bbac-cls:hover {
    background-color: #0b888d !important;
    border: 1px solid #0b888d !important;
    -moz-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5); }
  .bbac-cls:disabled {
    opacity: 0.6;
    filter: grayscale(60%);
    cursor: not-allowed;
    box-shadow: none !important; }
    .bbac-cls:disabled:hover {
      background-color: #0EADB3 !important; }

.natwest-cls {
  background-color: #42145f;
  color: #ffffff;
  border: 1px solid #42145f;
  -moz-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5); }
  .natwest-cls:hover {
    background-color: #2b0d3d !important;
    border: 1px solid #2b0d3d !important;
    -moz-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5); }
  .natwest-cls:disabled {
    opacity: 0.6;
    filter: grayscale(60%);
    cursor: not-allowed;
    box-shadow: none !important; }
    .natwest-cls:disabled:hover {
      background-color: #42145f !important; }

.hsbc-cls {
  background-color: #DB0011;
  color: #ffffff;
  border: 1px solid #DB0011;
  -moz-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5); }
  .hsbc-cls:hover {
    background-color: #b2000e !important;
    border: 1px solid #b2000e !important;
    -moz-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5); }
  .hsbc-cls:disabled {
    opacity: 0.6;
    filter: grayscale(60%);
    cursor: not-allowed;
    box-shadow: none !important; }
    .hsbc-cls:disabled:hover {
      background-color: #DB0011 !important; }

.n26-cls {
  background-color: #48AC98;
  color: #ffffff;
  border: 1px solid #48AC98;
  -moz-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5); }
  .n26-cls:hover {
    background-color: #3c8f7f !important;
    border: 1px solid #3c8f7f !important;
    -moz-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5); }
  .n26-cls:disabled {
    opacity: 0.6;
    filter: grayscale(60%);
    cursor: not-allowed;
    box-shadow: none !important; }
    .n26-cls:disabled:hover {
      background-color: #48AC98 !important; }

.revolut-cls {
  background-color: #805CF5;
  color: #ffffff;
  border: 1px solid transparent;
  -moz-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(#00ABFA, #805CF5);
  border-style: solid;
  border-image: linear-gradient(to top, #00ABFA, #805CF5); }
  .revolut-cls:hover {
    background-color: #6236f3 !important;
    border: 1px solid rgba(0, 0, 0, 0) !important;
    -moz-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5);
    box-shadow: 5px 5px 60px rgba(0, 0, 0, 0.5); }
  .revolut-cls:disabled {
    opacity: 0.6;
    filter: grayscale(60%);
    cursor: not-allowed;
    box-shadow: none !important; }
    .revolut-cls:disabled:hover {
      background-color: #805CF5 !important; }

.oAuth__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px 0 0 40px; }

.oAuth__granted {
  font: normal normal 700 16px/1.2 "Open Sans", sans-serif;
  color: #ffffff;
  margin-top: 20px;
  padding: 8px 16px;
  border-radius: 20px; }
  .oAuth__granted i {
    margin-left: 10px; }
  .oAuth__granted--positive {
    background-color: #009F25; }
  .oAuth__granted--negative {
    background-color: #A50000; }
  .oAuth__granted--pending {
    background-color: #CB681A; }

.provider__info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 30px 30px 30px;
  border-radius: 12px;
  width: 66%; }

.provider__label {
  display: block; }
  .provider__label b {
    font-weight: 600; }

.otp {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; }
  .otp label {
    font-weight: 600; }
  .otp input {
    margin-top: 20px;
    padding: 4px 8px;
    border-radius: 20px;
    border: 1px solid #666666;
    outline: none !important;
    width: 105px; }
  .otp__btn {
    margin-top: 20px;
    background-color: #666666;
    color: #ffffff;
    border-radius: 20px;
    border: 1px solid #666666;
    width: 105px;
    padding: 4px 10px;
    cursor: pointer;
    outline: none !important; }
    .otp__btn:hover:not(:disabled) {
      background-color: #333333; }
    .otp__btn:disabled {
      opacity: 0.6;
      cursor: not-allowed; }

.auth-list {
  margin-top: 40px;
  width: 100%; }
  .auth-list__accordion--wrapper {
    margin-top: 20px;
    width: 100%; }
  .auth-list h3 {
    font: normal normal 700 18px/48px "Open Sans", sans-serif;
    border-left: 5px solid #C9B4B7;
    padding-left: 1.25rem;
    color: #785E62; }
  .auth-list .accordion .card {
    border: 1px solid #C9B4B7; }
    .auth-list .accordion .card:not(:last-of-type) {
      border-bottom: 0; }
    .auth-list .accordion .card-header {
      background: rgba(201, 180, 183, 0.4);
      cursor: pointer;
      color: #594648;
      font-weight: 600; }
  .auth-list pre {
    white-space: pre-wrap;
    color: #362B30; }
  .auth-list__transactions {
    padding-top: 20px; }
    .auth-list__transactions label {
      font: normal normal 700 14px/1 "Open Sans", sans-serif;
      margin-bottom: 0 !important; }
    .auth-list__transactions--datepicker {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center; }
      .auth-list__transactions--datepicker blockquote {
        font-size: 14px;
        margin: 5px 10px 5px 1px; }
      .auth-list__transactions--datepicker i[class^="calendar"] {
        vertical-align: middle;
        border-top: 1px solid #9A9A98;
        border-bottom: 1px solid #9A9A98;
        padding: 3px 8px;
        cursor: pointer;
        color: #5a5a58; }
        .auth-list__transactions--datepicker i[class^="calendar"].calendar-left {
          border-left: 1px solid #9A9A98; }
      .auth-list__transactions--datepicker input {
        max-width: 110px;
        padding: 2px 5px;
        outline: none !important;
        cursor: pointer;
        color: #5a5a58; }
    .auth-list__transactions--btn {
      background-color: #B0A6A7;
      color: #ffffff;
      border-radius: 3px;
      border: 1px solid #B0A6A7;
      margin-left: 20px;
      width: 105px;
      padding: 3px;
      cursor: pointer;
      outline: none !important; }
      .auth-list__transactions--btn:hover:not(:disabled) {
        background-color: #7f7172; }
      .auth-list__transactions--btn i {
        margin-left: 10px; }
    .auth-list__transactions--result {
      margin-top: 25px;
      border: 1px solid #C9B4B7;
      border-radius: 5px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start; }
      .auth-list__transactions--result h4 {
        font: normal normal 600 14px/1 "Open Sans", sans-serif;
        color: #6a5356;
        margin-top: -37px;
        margin-left: -10px;
        background-color: #ffffff;
        padding: 10px; }

.payment h3 {
  background-color: rgba(201, 180, 183, 0.4);
  color: #594648;
  font: normal normal 600 16px/24px "Open Sans", sans-serif;
  padding: .75rem 1.25rem;
  margin: 20px 0 0 0;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border: 1px solid #C9B4B7; }

.payment pre {
  border-left: 1px solid #C9B4B7;
  border-bottom: 1px solid #C9B4B7;
  border-right: 1px solid #C9B4B7;
  padding: 25px;
  border-radius: 0.25rem;
  white-space: pre-wrap;
  word-break: break-all; }

.loader {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 25px; }
  .loader.full__width {
    width: 100% !important; }
  .loader.fixed__width {
    width: 66% !important; }

.lds-circle {
  display: inline-block;
  transform: translateZ(1px);
  height: 25px;
  margin-top: 8px; }

.lds-circle > div {
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-left: 10px;
  background: #666666;
  animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite; }

.notification-toast {
  position: fixed !important;
  left: calc(50% - 300px);
  right: calc(50% - 300px);
  z-index: 800;
  top: 0;
  width: 100%;
  height: 105px;
  max-width: 600px !important;
  align-items: center;
  justify-content: center; }
  .notification-toast.show {
    display: flex; }
  .notification-toast:not(.show) {
    display: none; }
  .notification-toast.error {
    background-color: #E4C4C6; }
    .notification-toast.error .toast-body span {
      color: #5A3033; }
  .notification-toast.success {
    background-color: #C4E4CA; }
    .notification-toast.success .toast-body span {
      color: #305A38; }
  .notification-toast .toast-body {
    display: flex;
    align-items: center; }
    .notification-toast .toast-body span {
      line-height: 20px; }
      .notification-toast .toast-body span:first-child {
        font-size: 30px;
        margin: 0 15px 0 0; }
      .notification-toast .toast-body span:nth-child(2) {
        font-size: 16px;
        height: 16px;
        font-weight: 600; }
